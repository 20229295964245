const de = {
  common: {
    or: 'oder',
    add: 'Hinzufügen ',
    addRow: 'Zeile hinzufügen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    reset: 'Zurücksetzen',
    save: 'Speichern',
    submit: 'Bestätigen',
    next: 'Nächste',
    previous: 'Zurück',
    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    search: 'Suchen',
    edit: 'Bearbeiten',
    remove: 'Entfernen',
    new: 'Neu',
    export: 'Exportieren',
    exportActiveKst: 'Exportieren - Aktive KST',
    noDataToExport: 'Keine Daten zu exportieren',
    import: 'Importieren',
    discard: 'Verwerfen',
    revise: 'Überarbeiten',
    release: 'Freigeben',
    reject: 'Ablehnen',
    process: 'Verarbeiten',
    yes: 'Ja',
    no: 'Nein',
    pause: 'Pause',
    areYouSure: 'Sind Sie sicher?',
    view: 'Ansicht',
    destroy: 'Löschen',
    mustSelectARow: 'Muss eine Zeile auswählen',
    confirm: 'Bestätigen',
    requestRelease: 'Freigabe anfordern',
    actions: 'Actions',
    finish: 'Beenden',
    changePassword: 'Kennwort ändern',
    updatePassword: 'Kennwort aktualisieren',
    password: 'Kennwort',
    approve: 'Genehmigen',
    propose: 'Vorschlagen',
    discardFormConfirm:
      'Sind Sie sicher? Alle nicht gespeicherten Daten gehen verloren.',
    openMitarbeiter: 'Mitarbeiter öffnen',
    download: 'Herunterladen',
    preview: 'Vorschau',
    status: 'Status',
    send: 'Senden',
    showAdvanced: 'Erweiterte Optionen',
    sendForApproval: 'zur Freigabe senden',
    manualPreparation: 'Manuell Vorbereitung',
    prepareTemplate: 'Vorlage vorbereiten',
    prepare: 'Vorbereiten',
    setInactive: 'Auf Inaktiv gesetzt',
    setActive: 'Auf Aktiv gesetzt',
    newKarenz: 'Neue Karenz',
    emailTest: 'Email test',
  },
  app: {
    title: 'Personal',
    titleTest: 'TEST - Personal',
  },

  entities: {
    vorlageDienstgeber: {
      name: 'Vorlage Dienstgeber',
      label: 'Vorlagen Dienstgeber',
      menu: 'Vorlagen Dienstgeber',
      list: {
        menu: 'Vorlagen Dienstgeber',
        title: 'Vorlagen Dienstgeber',
      },
      create: {
        success: 'Vorlage erfolgreich gespeichert',
      },
      update: {
        success: 'Vorlage erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Vorlage erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Vorlage(n) erfolgreich gelöscht',
      },
      edit: {
        title: 'Vorlage bearbeiten',
      },
      fields: {
        id: 'Id',
        vorlage: 'Vorlage',
        type: 'Vorlagentyp',
        name: 'Name',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        type: {
          arbeitsbestaetigung: 'Arbeitsbestätigung',
          kuendigung: 'Kündigung',
          vertrag: 'Vertrag',
          vertragsergaenzung: 'Vertragsergänzung',
          vertragsaenderung: 'Vertragsänderung',
          karenz: 'Karenz',
        },
      },
      new: {
        title: 'Neu Vorlage',
      },
      view: {
        title: 'Ansicht Vorlage ',
      },
    },

    vorlageDienstnehmer: {
      name: 'Vorlage Dienstnehmer',
      label: 'Vorlagen Dienstnehmer',
      menu: 'Vorlagen Dienstnehmer',
      list: {
        menu: 'Vorlagen Dienstnehmer',
        title: 'Vorlagen Dienstnehmer',
      },
      create: {
        success: 'Vorlage erfolgreich gespeichert',
      },
      update: {
        success: 'Vorlage erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Vorlage erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Vorlage(n) erfolgreich gelöscht',
      },
      edit: {
        title: 'Vorlage bearbeiten',
      },
      fields: {
        id: 'Id',
        vorlage: 'Vorlage',
        type: 'Vorlagentyp',
        name: 'Name',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        type: {
          karenz: 'Karenz',
          kuendigungDurchDN: 'Kündigung d. DN',
          arbeitszeitregelung: 'Arbeitszeitregelung',
          datenblatt: 'Datenblatt',
          urlaubsmeldung: 'Urlaubsmeldung',
          zeitausgleich: 'Zeitausgleich',
          dienstantrittsmeldungDienstendemeldung:
            'Dienstantrittsmeldung/Dienstendemeldung',
        },
      },
      new: {
        title: 'Neu Vorlage',
      },
      view: {
        title: 'Ansicht Vorlage ',
      },
    },

    bestaetigung: {
      name: 'Bestätigung',
      label: 'Bestätigung',
      menu: 'Bestätigung',
      list: {
        menu: 'Bestätigung',
        Titel: 'Bestätigung',
      },
      create: {
        success: 'Bestätigung erfolgreich gespeichert',
      },
      update: {
        success: 'Bestätigung erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Bestätigung erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Bestätigungen erfolgreich gelöscht',
      },
      edit: {
        title: 'Bestätigung bearbeiten',
      },
      fields: {
        id: 'Id',
        vorlageDienstgeber: 'Vorlagentyp',
        file: 'Datei',
        bestaetigungType: 'Bestätigungsart',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        bestaetigungType: {
          mitGehalt: 'mitGehalt',
          mitGehaltEnglisch: 'mitGehaltEnglisch',
          inKarenz: 'inKarenz',
          warBeschaeftigt: 'warBeschaeftigt',
          istBeschaeftigt: 'istBeschaeftigt',
        },
      },
      new: {
        title: 'Neue Bestätigung',
      },
      view: {
        title: 'Ansicht Bestätigung',
      },
    },

    mitarbeiter: {
      name: 'Mitarbeiter',
      label: 'Mitarbeiter',
      menu: 'Mitarbeiter',
      list: {
        menu: 'Mitarbeiter',
        title: 'Mitarbeiter',
      },
      create: {
        success: 'Mitarbeiter erfolgreich gespeichert',
      },
      update: {
        success: 'Mitarbeiter erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Mitarbeiter erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Mitarbeiter erfolgreich gelöscht',
      },
      edit: {
        title: 'Mitarbeiter bearbeiten',
      },
      createNotiz: {
        success: 'Notiz erfolgreich gespeichert',
      },
      bestaetigungDownload: {
        success: 'Bestaetigung erfolgreich heruntergeladen',
      },
      fields: {
        id: 'Id',
        mitarbeiterNr: 'Pers.Nr.',
        anrede: 'Anrede',
        titel: 'Titel',
        nachname: 'Nachname',
        vorname: 'Vorname',
        vollname: 'Name',
        vollNameExport: 'Name',
        email: 'E-Mail',
        fakultaet: 'Fakultät',
        strasse: 'Straße HausNr/Top',
        plz: 'PLZ',
        stadt: 'Stadt',
        svNummer: 'SV-Nummer',
        geburtsdatum: 'Geburtsdatum',
        telefon: 'Telefon',
        beruf: 'Beruf',
        funktion: 'Funktion',
        staatsbuergerschaft: 'Staatsbürgerschaft',
        urlaubstage: 'Urlaubstage',
        arbeitstage: 'Arbeitstage',
        saPers: 'PERS',
        saSex: 'SEX',
        saGebj: 'GEBJ',
        saStudienrichtung: 'Studienrichtung',
        saFte: 'FTE',
        sa_sex: 'SEX',
        saStaat: 'STAAT',
        sa_ausb: 'AUSB',
        sa_fkt: 'FKT',
        sa_taet1: 'TAET1',
        sa_taet2: 'TAET2',
        sa_verw: 'VERW',
        iban: 'IBAN',
        bic: 'BIC',
        aufenthaltstitelVon: 'Gültig von',
        aufenthaltstitelBis: 'Gültig bis',
        aufenthaltstitelArt: 'Art',
        uBahn: 'U-Bahn',
        persoenlicheData: 'Persönliche Daten',
        mitteilung: 'Mitteilungen',
        vertrag: 'Verträge',
        karenzDoku: 'Karenzen',
        krankmeldung: 'Krankmeldung',
        grundgehaltGesamtbrutto: 'Brutto',
        gesamtbrutto: 'Brutto',
        gesamtkosten: 'Gesamtkosten',
        gesamtwochenstunden: 'WoStd',
        mandant: 'Mandant',
        verwendung: 'Verwendung',
        probeMonat: 'Probemonat',
        vertragsart: 'Vertragsart',
        eintrittsdatum: 'Eintrittsdatum',
        austrittsdatum: 'Austrittsdatum',
        dienstjahre: 'Dienstjahre',
        dienstjahreInt: 'DJ',
        gehaltProJahr: 'Gehalt p.a.',
        karenzvertretungMitarbeiter: 'Karenzvertretung',
        kostenstelle: 'Kostenstelle',
        hauptkostenstelle: 'Hauptkostenstelle',
        kostentraeger: 'Kostenträger',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        wochenstunden: 'Wochenstunden',
        brutto: 'Brutto',
	neuBrutto: 'Neu Brutto',
        verwendungKostenstelle: 'Verwendung Kst',
	einstufung: 'Einstufung',
        ohneKostenstelle: 'ohne Kst',
        onlyInactive: 'inaktiv',
        onlyInKarenz: 'in Karenz',
        onlyInPlan: 'in Plan',
        status: 'Status',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt',
        updatedAtRange: 'Aktualisiert',
      },
      enumerators: {
        anrede: {
          herr: 'Herr',
          frau: 'Frau',
        },
        saSex: {
          W: 'w',
          M: 'm',
          X: 'x',
          O: 'o',
          I: 'i',
          K: 'k',
        },
        status: {
          plan: 'Plan',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          ruhezeit: 'Ruhezeit',
          mutterschutz: 'Mutterschutz',
          mutterkarenz: 'Mutterkarenz',
          bildungskarenz: 'Bildungskarenz',
          papamonat: 'Papamonat',
          elternteilzeit: 'Elternteilzeit',
          bildungsteilzeit: 'Bildungsteilzeit',
        },
      },
      new: {
        title: 'Neuer Mitarbeiter',
      },
      view: {
        title: 'Ansicht Mitarbeiter',
      },
      importer: {
        title: 'Mitarbeiter importieren',
        fileName: 'mitarbeiter_import_vorlage',
        hint: 'Die Spalten Dateien/Bilder müssen die URLs der Dateien enthalten, die durch Leerzeichen getrennt sind.',
      },
    },

 mitarbeiterExportWinline: {
      fields: {
        mitarbeiterNr: 'ANNr',
        anrede: 'Anrede',
        titel: 'Titel',
        vorname: 'Vorname',
        nachname: 'Nachname',
        titelNach: 'Titelnach',
        strasse: 'Stra�e',
        plz: 'PLZ',
        stadt: 'Ort',
        lkz: 'LKZ',
        saStaat: 'Staatsangeh�rigkeit',
        telefon: 'Telefonnummer',
        email: 'EMail Adresse',
        sa_sex: 'Geshlecht',
        iban: 'IBAN',
        bic: 'BIC',
        lkzBank: 'LKZ Bank',
        eintrittsdatum: 'Eintritt',
        austrittsdatum: 'Austritt',
        svNummer: 'SVNr',
        geburtsdatum: 'GebDatum',
        inaktiv: 'Inaktiv',
      },
      enumerators: {
        anrede: {
          herr: 'Herr',
          frau: 'Frau',
        },
      },
    },

    aenderung: {
      name: 'Änderung',
      label: 'Änderung',
      menu: 'Änderung',
      list: {
        menu: 'Änderung',
        title: 'Änderung',
      },
      sendEmail: {
        success: 'Änderung erfolgreich gesendet',
      },
      fields: {
        id: 'Id',
        mitarbeiterNr: 'Pers.Nr.',
        aenderung: 'Änderung',
        anrede: 'Anrede',
        titel: 'Titel',
        nachname: 'Nachname',
        vorname: 'Vorname',
        vollname: 'Name',
        email: 'E-Mail',
        fakultaet: 'Fakultät',
        strasse: 'Straße HausNr/Top',
        plz: 'PLZ',
        stadt: 'Stadt',
        svNummer: 'SV-Nummer',
        geburtsdatum: 'Geburtsdatum',
        telefon: 'Telefon',
        beruf: 'Beruf',
        funktion: 'Funktion',
        staatsbuergerschaft: 'Staatsbürgerschaft',
        urlaubstage: 'Urlaubstage',
        arbeitstage: 'Arbeitstage',
        saPers: 'PERS',
        saSex: 'SEX',
        saGebj: 'GEBJ',
        saStudienrichtung: 'Studienrichtung',
        saFte: 'FTE',
        saStaat: 'STAAT',
        sa_ausb: 'AUSB',
        sa_fkt: 'FKT',
        sa_taet1: 'TAET1',
        sa_taet2: 'TAET2',
        sa_verw: 'VERW',
        iban: 'IBAN',
        bic: 'BIC',
        uBahn: 'U-Bahn',
        persoenlicheData: 'Persönliche Daten',
        mitteilung: 'Mitteilungen',
        vertrag: 'Verträge',
        karenzDoku: 'Karenzen',
        krankmeldung: 'Krankmeldung',
        grundgehaltGesamtbrutto: 'Brutto',
        gesamtbrutto: 'Brutto',
        gesamtkosten: 'Gesamtkosten',
        gesamtwochenstunden: 'WoStd',
        mandant: 'Mandant',
        verwendung: 'Verwendung',
        probeMonat: 'Probemonat',
        vertragsart: 'Vertragsart',
        eintrittsdatum: 'Eintrittsdatum',
        austrittsdatum: 'Austrittsdatum',
        gehaltProJahr: 'Gehalt p.a.',
        karenzvertretungMitarbeiter: 'Karenzvertretung',
        kostenstelle: 'Kostenstelle',
        hauptkostenstelle: 'Hauptkostenstelle',
        kostentraeger: 'Kostenträger',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        wochenstunden: 'Wochenstunden',
        brutto: 'Brutto',
        verwendungKostenstelle: 'Verwendung Kostenstelle',
        zweckArt: 'Zweck/Art',
        ohneKostenstelle: 'Ohne Kostenstelle',
        status: 'Status',
        stand: 'Stand',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        updatedAtRange: 'Änderungen für',
      },
      enumerators: {
        status: {
          gesendet: 'Gesendet',
          nichtGesendet: 'Nicht gesendet',
        },
      },
    },

    auswertung: {
      name: 'Auswertung',
      label: 'Auswertung',
      menu: 'Auswertung',
      list: {
        menu: 'Auswertung',
        title: 'Auswertung',
      },
      fields: {
        id: 'Id',
        mitarbeiterNr: 'Pers.Nr.',
        aenderung: 'Auswertung',
        anrede: 'Anrede',
        titel: 'Titel',
        nachname: 'Nachname',
        vorname: 'Vorname',
        vollname: 'Name',
        email: 'E-Mail',
        fakultaet: 'Fakultät',
        strasse: 'Straße HausNr/Top',
        plz: 'PLZ',
        stadt: 'Stadt',
        svNummer: 'SV-Nummer',
        geburtsdatum: 'Geburtsdatum',
        telefon: 'Telefon',
        beruf: 'Beruf',
        funktion: 'Funktion',
        staatsbuergerschaft: 'Staatsbürgerschaft',
        urlaubstage: 'Urlaubstage',
        arbeitstage: 'Arbeitstage',
        saPers: 'PERS',
        saSex: 'SEX',
        saGebj: 'GEBJ',
        saStudienrichtung: 'Studienrichtung',
        saFte: 'FTE',
        saStaat: 'STAAT',
        sa_ausb: 'AUSB',
        sa_fkt: 'FKT',
        sa_taet1: 'TAET1',
        sa_taet2: 'TAET2',
        sa_verw: 'VERW',
        iban: 'IBAN',
        bic: 'BIC',
        uBahn: 'U-Bahn',
        persoenlicheData: 'Persönliche Daten',
        mitteilung: 'Mitteilungen',
        vertrag: 'Verträge',
        karenzDoku: 'Karenzen',
        krankmeldung: 'Krankmeldung',
        grundgehaltGesamtbrutto: 'Brutto',
        gesamtbrutto: 'Brutto',
        gesamtkosten: 'Gesamtkosten',
        gesamtwochenstunden: 'WoStd',
        totalGesamtkosten: 'Total GK',
        totalBrutto: 'Total Brutto',
        mandant: 'Mandant',
        verwendung: 'Verwendung',
        probeMonat: 'Probemonat',
        vertragsart: 'Vertragsart',
        eintrittsdatum: 'Eintrittsdatum',
        austrittsdatum: 'Austrittsdatum',
        gehaltProJahr: 'Gehalt p.a.',
        karenzvertretungMitarbeiter: 'Karenzvertretung',
        kostenstelle: 'Kostenstelle',
        hauptkostenstelle: 'Hauptkostenstelle',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        wochenstunden: 'Wochenstunden',
        brutto: 'Brutto',
        verwendungKostenstelle: 'Verwendung KST',
	einstufung: 'Einstufung',
        kostentraeger: 'Kostenträger',
        zweckArt: 'Zweck/Art',
        ohneKostenstelle: 'Ohne Kostenstelle',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        updatedAtRange: 'Auswertung für',
        auswertungForRange: 'Auswertung für:',
      },
    },

    vertrag: {
      name: 'Vertrag',
      Bezeichnung: 'Vertrag',
      menu: 'Vertrag',
      list: {
        menu: 'Vertrag',
        title: 'Vertrag',
      },
      create: {
        success: 'Vertrag erfolgreich gespeichert',
      },
      update: {
        success: 'Vertrag erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Vertrag erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Vertrag erfolgreich gelöscht',
      },
      sendEmail: {
        success: 'Vertrag erfolgreich an Mitarbeiter gesendet',
      },
      edit: {
        title: 'Vertrag bearbeiten',
      },
      uploadManual: {
        title: 'Vertrag hochladen',
        uploadButton: 'Alte Verträge',
      },
      vertragsentwurf: {
        success: 'Vertragsentwurf erfolgreich erstellt',
      },
      fields: {
        id: 'Id',
        sentAt: 'Gesendet an MA',
        decisionDate: 'Entscheidungsdatum',
        vertragUploadDate: 'Hochlade-Datum',
        vertragsentwurf: 'Vertragsentwurf',
        unterschriebenerVertrag: 'Unterschriebener Vertrag',
        entwurfStatus: 'Entwurfsstatus',
        vertragStatus: 'Vertragsstatus',
        eintrittsdatum: 'Eintrittsdatum',
        type: 'Art',
        vorlageDienstgeber: 'Vorlagentyp',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        entwurfStatus: {
          releasePending: 'Freigabe ausstehend',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          rejected: 'Abgelehnt',
          revisionRequested: 'Revision angefordert',
        },
        vertragStatus: {
          active: 'Aktiv',
          inactive: 'Inaktiv',
        },
      },
      new: {
        title: 'Neuer Vertrag',
      },
      view: {
        title: 'Vertrag ansehen',
      },
    },

    vertragsergaenzung: {
      name: 'Vertragsergänzung',
      Bezeichnung: 'Vertragsergänzung',
      menu: 'Vertragsergänzung',
      list: {
        menu: 'Vertragsergänzung',
        title: 'Vertrag',
      },
      create: {
        success: 'Vertragsergänzung erfolgreich gespeichert',
      },
      update: {
        success: 'Vertragsergänzung erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Vertragsergänzung erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Vertragsergänzung erfolgreich gelöscht',
      },
      sendEmail: {
        success: 'Vertragsergänzung erfolgreich an Mitarbeiter gesendet',
      },
      edit: {
        title: 'Vertragsergänzung bearbeiten',
      },
      uploadManual: {
        title: 'Vertragsergänzung hochladen',
        uploadButton: 'Alte Vertragsergänzungen',
      },
      vertragsentwurf: {
        success: 'Vertragsentwurf erfolgreich erstellt',
      },
      fields: {
        id: 'Id',
        sentAt: 'Gesendet an MA',
        decisionDate: 'Entscheidungsdatum',
        vertragsergaenzungUploadDate: 'Hochlade-Datum',
        entwurf: 'Entwurf',
        unterschriebenerVertragsergaenzung:
          'Unterschriebener Vertragsergänzung',
        entwurfStatus: 'Entwurfsstatus',
        vertragsergaenzungStatus: 'Vertragsergänzung status',
        eintrittsdatum: 'Eintrittsdatum',
        type: 'Art',
        vertrag: 'Vertrag',
        vorlageDienstgeber: 'Vorlagentyp',
        kostenstelle: 'Kostenstelle',
        von: 'Von',
        bis: 'Bis',
        brutto: 'Brutto',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        entwurfStatus: {
          releasePending: 'Freigabe ausstehend',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          rejected: 'Abgelehnt',
          revisionRequested: 'Revision angefordert',
        },
        vertragsergaenzungStatus: {
          active: 'Aktiv',
          inactive: 'Inaktiv',
        },
      },
      new: {
        title: 'Neuer Vertragsergänzung',
      },
      view: {
        title: 'Vertragsergänzung ansehen',
      },
    },

    vertragsaenderung: {
      name: 'Vertragsänderung',
      Bezeichnung: 'Vertragsänderung',
      menu: 'Vertragsänderung',
      list: {
        menu: 'Vertragsänderung',
        title: 'Vertrag',
      },
      create: {
        success: 'Vertragsänderung erfolgreich gespeichert',
      },
      update: {
        success: 'Vertragsänderung erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Vertragsänderung erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Vertragsänderung erfolgreich gelöscht',
      },
      sendEmail: {
        success: 'Vertragsänderung erfolgreich an Mitarbeiter gesendet',
      },
      edit: {
        title: 'Vertragsänderungen bearbeiten',
      },
      uploadManual: {
        title: 'Vertragsänderungen hochladen',
        uploadButton: 'Alte Vertragsänderungen',
      },
      vertragsentwurf: {
        success: 'Vertragsentwurf erfolgreich erstellt',
      },
      fields: {
        id: 'Id',
        sentAt: 'Gesendet an MA',
        decisionDate: 'Entscheidungsdatum',
        vertragsaenderungUploadDate: 'Hochlade-Datum',
        entwurf: 'Entwurf',
        unterschriebenerVertragsaenderung: 'Unterschriebener Vertragsänderung',
        entwurfStatus: 'Entwurfsstatus',
        vertragsaenderungStatus: 'Vertragsstatus',
        eintrittsdatum: 'Eintrittsdatum',
        type: 'Art',
        vertrag: 'Vertrag',
        vorlageDienstgeber: 'Vorlagentyp',
        kostenstelle: 'Kostenstelle',
        von: 'Von',
        bis: 'Bis',
        brutto: 'Brutto',
        inflationGehaltsanpassung: 'Inflation - Gehaltsanpassung',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        entwurfStatus: {
          releasePending: 'Freigabe ausstehend',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          rejected: 'Abgelehnt',
          revisionRequested: 'Revision angefordert',
        },
        vertragsaenderungStatus: {
          active: 'Aktiv',
          inactive: 'Inaktiv',
        },
      },
      new: {
        title: 'Neuer Vertragsänderung',
      },
      view: {
        title: 'Vertragsänderung ansehen',
      },
    },

    beendigung: {
      name: 'Beendigung',
      label: 'Beendigung',
      menu: 'Beendigung',
      list: {
        menu: 'Beendigung',
        title: 'Beendigung',
      },
      create: {
        success: 'Beendigung erfolgreich gespeichert',
      },
      update: {
        success: 'Beendigung erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Beendigung erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Beendigung erfolgreich gelöscht',
      },
      sendEmail: {
        success: 'Kündigung erfolgreich an Mitarbeiter gesendet',
      },
      edit: {
        title: 'Beendigung bearbeiten',
      },
      uploadManual: {
        title: 'Beendigung hochladen',
        uploadButton: 'Alte Beendigungen',
      },
      fields: {
        id: 'Id',
        kuendigungMitDatum: 'Kündigung mit Datum',
        austrittsdatum: 'Austrittsdatum',
        decisionDate: 'Entscheidungsdatum',
        sentAt: 'Gesendet an MA',
        kuendigungEntwurf: 'Kündigung Entwurf',
        entwurfStatus: 'Entwurf Status',
        unterschriebeneKuendigung: 'Kündigung',
        kuendigungStatus: 'Kündigung Status',
        kuendigungUploadDate: 'Hochlade-Datum',
        type: 'Art der Vertragsbeendigung',
        vorlageDienstgeber: 'Vorlagentyp',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        entwurfStatus: {
          releasePending: 'Freigabe ausstehend',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          rejected: 'Abgelehnt',
          revisionRequested: 'Revision angefordert',
        },
        kuendigungStatus: {
          active: 'Aktiv',
          inactive: 'Inaktiv',
        },
        type: {
          beendigung_kuendigung_durchDN: 'Kündigung durch DN',
          beendigung_kuendigung_durchDG:
            'Kündigung durch DG - BETRIEBSRAT darüber informieren',
          beendigung_einvernehmlicheAufloesung: 'Einvernehmliche Auflösung',
        },
      },
      new: {
        title: 'Neue Beendigung',
      },
      view: {
        title: 'Ansicht Beendigung',
      },
    },
    approvalRequest: {
      list: {
        menu: 'Anträge auf Vertragsfreigabe',
        title: 'Anträge auf Vertragsfreigabe',
      },
      fields: {
        id: 'Id',
        approver: 'Genehmigender',
        status: 'Status',
        comment: 'Kommentar',
        updatedAt: 'Aktualisiert am',
      },
      enumerators: {
        status: {
          requested: 'Angefordert',
          approved: 'Genehmigt',
          rejected: 'Abgelehnt',
          revisionRequested: 'Revision angefordert',
        },
      },
    },

    mandant: {
      name: 'Mandant',
      label: 'Mandant',
      menu: 'Mandant',
      list: {
        menu: 'Mandant',
        title: 'Mandant',
      },
      create: {
        success: 'Mandant erfolgreich gespeichert',
      },
      update: {
        success: 'Mandant erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Mandant erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Mandant erfolgreich gelöscht',
      },
      edit: {
        title: 'Mandant bearbeiten',
      },
      fields: {
        id: 'Id',
        nummer: 'Nummer',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neuer Mandant',
      },
      view: {
        title: 'Ansicht Mandant',
      },
    },

    vertragsart: {
      name: 'Vertragsart',
      label: 'Vertragsart',
      menu: 'Vertragsart',
      list: {
        menu: 'Vertragsart',
        title: 'Vertragsart',
      },
      create: {
        success: 'Vertragsart erfolgreich gespeichert',
      },
      update: {
        success: 'Vertragsart erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Vertragsart erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Vertragsart erfolgreich gelöscht',
      },
      edit: {
        title: 'Vertragsart bearbeiten',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Vertragsart',
      },
      view: {
        title: 'Ansicht Vertragsart',
      },
    },

    fakultaet: {
      name: 'Fakultät',
      label: 'Fakultät',
      menu: 'Fakultät',
      list: {
        menu: 'Fakultät',
        title: 'Fakultät',
      },
      create: {
        success: 'Fakultät erfolgreich gespeichert',
      },
      update: {
        success: 'Fakultät erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Fakultät erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Fakultät erfolgreich gelöscht',
      },
      edit: {
        title: 'Fakultät bearbeiten',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Fakultät',
      },
      view: {
        title: 'Ansicht Fakultät',
      },
    },

    staatsbuergerschaft: {
      name: 'Staatsbürgerschaft',
      label: 'Staatsbürgerschaft',
      menu: 'Staatsbürgerschaft',
      list: {
        menu: 'Staatsbürgerschaft',
        title: 'Staatsbürgerschaft',
      },
      create: {
        success: 'Staatsbürgerschaft erfolgreich gespeichert',
      },
      update: {
        success: 'Staatsbürgerschaft erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Staatsbürgerschaft erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Staatsbürgerschaft erfolgreich gelöscht',
      },
      sendEmail: {
        success: 'Kündigung erfolgreich an Mitarbeiter gesendet',
      },
      edit: {
        title: 'Staatsbürgerschaft bearbeiten',
      },
      fields: {
        id: 'Id',
        langtext: 'Langtext',
        nationscode: 'Nationscode',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        status: {
          releasePending: 'Freigabe ausstehend',
          active: 'Aktiv',
          inactive: 'Inaktiv',
          rejected: 'Abgelehnt',
          revisionRequested: 'Revision angefordert',
        },
        type: {
          beendigung_kuendigung_durchDN: 'Kündigung durch DN',
          beendigung_kuendigung_durchDG: 'Kündigung durch DG',
          beendigung_einvernehmlicheAufloesung: 'Einvernehmliche Auflösung',
        },
      },
      new: {
        title: 'Neue Staatsbürgerschaft',
      },
      view: {
        title: 'Ansicht Staatsbürgerschaft',
      },
      importer: {
        title: 'Staatsbürgerschaft importieren',
        fileName: 'staatsbürgerschaft_import_vorlage',
        hint: 'Die Spalten Dateien/Bilder müssen die URLs der Dateien enthalten, die durch Leerzeichen getrennt sind.',
      },
    },

    verwendungKostenstelle: {
      name: 'Verwendung Kostenstelle',
      label: 'Verwendung Kostenstelle',
      menu: 'Verwendung Kst',
      list: {
        menu: 'Verwendung Kostenstelle',
        title: 'Verwendung Kostenstelle',
      },
      create: {
        success: 'Verwendung Kostenstelle erfolgreich gespeichert',
      },
      update: {
        success: 'Verwendung Kostenstelle erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Verwendung Auswertung/Kostenstelle erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Verwendung Kostenstelle erfolgreich gelöscht',
      },
      edit: {
        title: 'Verwendung Kostenstelle bearbeiten',
      },
      fields: {
        id: 'Id',
        nummer: 'Number',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Verwendung Kostenstelle',
      },
      view: {
        title: 'Ansicht Verwendung Kostenstelle',
      },
    },

    kostentraeger: {
      name: 'Kostenträger',
      label: 'Kostenträger',
      menu: 'Kostenträger',
      list: {
        menu: 'Kostenträger',
        title: 'Kostenträger',
      },
      create: {
        success: 'Kostenträger erfolgreich gespeichert',
      },
      update: {
        success: 'Kostenträger erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Kostenträger erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Kostenträger erfolgreich gelöscht',
      },
      edit: {
        title: 'Kostenträger bearbeiten',
      },
      fields: {
        id: 'Id',
        nummer: 'Nummer',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Kostenträger',
      },
      view: {
        title: 'Ansicht Kostenträger',
      },
    },

    kostenstelle: {
      name: 'Kostenstelle',
      label: 'Kostenstelle',
      menu: 'Kostenstelle',
      list: {
        menu: 'Kostenstelle',
        title: 'Kostenstelle',
      },
      create: {
        success: 'Kostenstelle erfolgreich gespeichert',
      },
      update: {
        success: 'Kostenstelle erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Kostenstelle erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Kostenstelle erfolgreich gelöscht',
      },
      edit: {
        title: 'Kostenstelle bearbeiten',
      },
      fields: {
        id: 'Id',
        nummer: 'Number',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Kostenstelle',
      },
      view: {
        title: 'Ansicht Kostenstelle',
      },
      importer: {
        title: 'Kostenstelle importieren',
        fileName: 'kostenstelle_import_vorlage',
        hint: 'Die Spalten Dateien/Bilder müssen die URLs der Dateien enthalten, die durch Leerzeichen getrennt sind.',
      },
    },

    hauptkostenstelle: {
      name: 'Hauptkostenstelle',
      label: 'Hauptkostenstelle',
      menu: 'Hauptkostenstelle',
      list: {
        menu: 'Hauptkostenstelle',
        title: 'Hauptkostenstelle',
      },
      create: {
        success: 'Hauptkostenstelle erfolgreich gespeichert',
      },
      update: {
        success: 'Hauptkostenstelle erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Hauptkostenstelle erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Hauptkostenstelle erfolgreich gelöscht',
      },
      edit: {
        title: 'Hauptkostenstelle bearbeiten',
      },
      fields: {
        id: 'Id',
        nummer: 'Nummer',
        name: 'Name',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Hauptkostenstelle',
      },
      view: {
        title: 'Ansicht Hauptkostenstelle',
      },
      importer: {
        title: 'Hauptkostenstelle importieren',
        fileName: 'hauptkostenstelle_import_vorlage',
        hint: 'Die Spalten Dateien/Bilder müssen die URLs der Dateien enthalten, die durch Leerzeichen getrennt sind.',
      },
    },

    einstufung: {
      name: 'Einstufung',
      label: 'Einstufung',
      menu: 'Einstufung',
      list: {
        menu: 'Einstufung',
        title: 'Einstufung',
      },
      create: {
        success: 'Einstufung erfolgreich gespeichert',
      },
      update: {
        success: 'Einstufung erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Einstufung erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Einstufung erfolgreich gelöscht',
      },
      edit: {
        title: 'Bearbeiten',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        grundgehaltBrutto: 'Grundgehalt',
	tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Einstufung',
      },
      view: {
        title: 'Ansicht Einstufung',
      },
      importer: {
        title: 'Einstufung importieren',
        fileName: 'einstufung_import_vorlage',
        hint: 'Die Spalten Dateien/Bilder müssen die URLs der Dateien enthalten, die durch Leerzeichen getrennt sind.',
      },
    },

    anstellung: {
      name: 'Anstellung',
      label: 'Anstellung',
      create: {
        success: 'Anstellung erfolgreich gespeichert',
      },
      update: {
        success: 'Anstellung erfolgreich aktualisiert',
      },
      fields: {
        id: 'Id',
        verwendung: 'Verwendung',
        mandant: 'Mandant',
        probeMonat: 'Probemonat',
        vertragsart: 'Vertragsart',
        beruf: 'Beruf',
        funktion: 'Funktion',
        eintrittsdatum: 'Eintrittsdatum',
        austrittsdatum: 'Austrittsdatum',
         dienstjahre: 'Dienstjahre',
        dienstjahreInt: 'DJ',
        gehaltProJahr: 'Gehalt p.a.',
        gesamtbrutto: 'Gesamtbrutto',
        gesamtkosten: 'Gesamtkosten',
        gesamtwochenstunden: 'WoStd',
        uBahn: 'U-Bahn',
        kommunalsteuer: 'KoST',
        isFiktive: 'Fiktive-EchteF',
        zusatzInfo: 'ZusatzInfo',
        inMutterschutz: 'Mutterschutz',
        inMutterkarenz: 'Mutterkarenz',
        inBildungskarenz: 'Bildungskarenz',
        inPapamonat: 'Papamonat',
        geplantenGeburtstermin: 'Geburtstermin',
        mutterschutzBeginn: 'Mutterschutz beginn',
        mutterschutzEnde: 'Mutterschutz ende',
        mutterkarenzBeginn: 'Mutterkarenz beginn',
        mutterkarenzEnde: 'Mutterkarenz ende',
        bildungskarenzBeginn: 'Bildungskarenz beginn',
        bildungskarenzEnde: 'Bildungskarenz ende',
        papamonatBeginn: 'Papamonat beginn',
        papamonatEnde: 'Papamonat ende',
        schutzfrist: 'Schutzfrist',
        karenz: 'Bestätigung',
        change: 'Ändern',
        karenzvertretungType: 'Karenzvertretung Typ',
        karenzvertretungMitarbeiter: 'Karenzvertretung',
        karenzvertretungFuerMitarbeiter: 'Karenzvertretung für',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        updatedBy: 'Aktualisiert von',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        probeMonat: { ja: 'Ja', nein: 'Nein' },
        gehaltProJahr: { 12: '12', 14: '14' },
        schutzfrist: { 8: '8 Wochen', 12: '12 Wochen' },
        karenzvertretungType: {
          karenzvertretung: 'Karenzvertretung',
          karenzvertretungFuer: 'Karenzvertretung für',
        },
      },
      view: {
        title: 'Ansicht Anstellung',
      },
    },

    karenz: {
      name: 'Mutter- Vaterkarenz',
      label: 'Karenz',
      create: {
        success: 'Karenz erfolgreich gespeichert',
      },
      dialogTitle: {
        new: 'Neue Karenz',
        edit: 'Karenz bearbeiten',
        view: 'Ansicht Karenz',
      },
      update: {
        success: 'Karenz erfolgreich aktualisiert',
      },
      confirmation: {
        message:
          'Alle eingegebenen Feldwerte werden gelöscht und die hochgeladenen Dateien entfernt. Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      fields: {
        id: 'Id',
        geplantenGeburtstermin: 'Geburtstermin',
        beginn: 'Beginn',
        ende: 'Ende',
        type: 'Type',
        schutzfrist: 'Schutzfrist',
        karenzvertretungType: 'Karenzvertretung Typ',
        karenzvertretungMitarbeiter: 'Karenzvertretung',
        karenzvertretungFuerMitarbeiter: 'Karenzvertretung für',
        bestaetigung: 'Bestätigung',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        updatedBy: 'Aktualisiert von',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        probeMonat: { ja: 'Ja', nein: 'Nein' },
        gehaltProJahr: { 12: '12', 14: '14' },
        schutzfrist: { 8: '8 Wochen', 12: '12 Wochen' },
        karenzvertretungType: {
          karenzvertretung: 'Karenzvertretung',
          karenzvertretungFuer: 'Karenzvertretung für',
        },
        type: {
          mutterschutz: 'Mutterschutz',
          mutterkarenz: 'Mutterkarenz',
          bildungskarenz: 'Bildungskarenz',
          elternteilzeit: 'Elternteilzeit',
          bildungsteilzeit: 'Bildungsteilzeit',
          papamonat: 'Papamonat',
        },
      },
    },

    beruf: {
      name: 'Beruf',
      label: 'Beruf',
      create: {
        success: 'Beruf erfolgreich gespeichert',
      },
      update: {
        success: 'Beruf erfolgreich aktualisiert',
      },
      fields: {
        id: 'Id',
        name: 'Name',
      },
      new: {
        title: 'Neuer Beruf',
      },
      view: {
        title: 'Ansicht Beruf',
      },
    },

    funktion: {
      name: 'Funktion',
      label: 'Funktion',
      create: {
        success: 'Funktion erfolgreich gespeichert',
      },
      update: {
        success: 'Funktion erfolgreich aktualisiert',
      },
      fields: {
        id: 'Id',
        name: 'Name',
      },
      new: {
        title: 'Neuer Funktion',
      },
      view: {
        title: 'Ansicht Funktion',
      },
    },

    gennehmigung: {
      name: 'Anstehende Aufgaben',
      headers: {
        vertrag: 'Verträge',
        vertragsaenderung: 'Vertragsänderung',
        vertragsergaenzung: 'Vertragsergänzung',
        beendigung: 'Beendigung',
      },
    },

    grundgehalt: {
      name: 'Gehalt',
      label: 'Grundgehalt',
      fields: {
        id: 'Id',
        kostenstelle: 'Kostenstelle',
        hauptkostenstelle: 'Hauptkostenstelle',
        kostentraeger: 'Kostenträger',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        wochenstunden: 'WoStd',
        brutto: 'Brutto Kst',
        gesamtkosten: 'GK Kst',
        fakultaet: 'Fakultät',
        verwendungKostenstelle: 'Verwendung Kst',
        einstufung: 'Einstufung',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {
        status: { active: 'Active', inactive: 'Inactive' },
      },
    },

    gehaltszulage: {
      name: 'Zweck des Gehalts',
      label: 'Gehaltszulage',
      fields: {
        id: 'Id',
        zweckArt: 'Zweck/Art',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        brutto: 'Brutto pro Monat',
        gesamtkosten: 'GK pro Kst ',
        kostenstelle: 'Kostenstelle',
        hauptkostenstelle: 'Hauptkostenstelle',
        kostentraeger: 'Kostenträger',
      },
      enumerators: {
        status: { active: 'Active', inactive: 'Inactive' },
      },
    },

    statistikAustria: {
      name: 'Statistik Austria',
      label: 'Statistik Austria',
      menu: 'Statistik Austria',
      list: {
        menu: 'Statistik Austria',
        title: 'Statistik Austria',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        mitarbeiterNr: 'Pers.Nr.',
        pers: 'PERS',
        sex: 'SEX',
        gebj: 'GEBJ',
        staat: 'STAAT',
        ausb: 'AUSB',
        taet1: 'TAET1',
        taet2: 'TAET2',
        fte: 'FTE',
        verw: 'VERW',
        fkt: 'FKT',
        year: 'Jahr',
        plan: 'Plan 1.9 - 31.8',
      },
    },

    knowledgeBase: {
      name: 'knowledgeBase',
      label: 'Knowledge Base',
      menu: 'Knowledge Base',
      list: {
        menu: 'Knowledge Base',
        title: 'Knowledge Base',
      },
      create: {
        success: 'Issue erfolgreich gespeichert',
      },
      update: {
        success: 'Issue erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Issue erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Issue erfolgreich gelöscht',
      },
      edit: {
        title: 'Bearbeiten',
      },
      fields: {
        id: 'Id',
        issue: 'Issue',
        resolution: 'Resolution',
        tag: 'Tag',
        document: 'Dokument',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        createdAtRange: 'Erstellt am',
      },
      enumerators: {},
      new: {
        title: 'Neu Issue',
      },
      view: {
        title: 'Ansicht Issue',
      },
    },

    calendar: {
      name: 'Kalender',
      label: 'Kalender',
      menu: 'Kalender',
      create: {
        success: 'Kalendereintrag erfolgreich erstellt',
      },
      update: {
        success: 'Kalendereintrag erfolgreich aktualisiert',
      },
      destroy: {
        success: 'Kalendereintrag erfolgreich gelöscht',
      },
      destroyAll: {
        success: 'Kalendereinträge erfolgreich gelöscht',
      },
      edit: {
        title: 'Eintrag bearbeiten',
      },
      fields: {
        id: 'Id',
        title: 'Titel',
        startDate: 'Anfangsdatum',
        endDate: 'Enddatum',
        url: 'URL',
        finished: 'Erledigt',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
      },
      options: {
        title: 'Kalenderoptionen',
        displayPeriod: 'Zeitraum',
        periodCount: 'Zeitraum Anzahl',
        displayWeekNumbers: 'Anzahl der Abgabewoche',
      },
      new: {
        title: 'Neuer Eintrag',
      },
      view: {
        title: 'Eintrag ansehen',
      },
    },
  },

  auth: {
    profile: {
      title: 'Profil bearbeiten',
      success: 'Profil erfolgreich aktualisiert',
    },
    createAnAccount: 'Kontoregistrierung anfordern',
    rememberMe: 'Erinnere mich',
    forgotPassword: 'Passwort vergessen',
    signin: 'Anmelden ',
    signup: 'Anmeldung',
    requestRegistration: 'Registrierung anfordern',
    signout: 'Abmelden',
    backToSignin: 'Zurück zur Anmeldung',
    alreadyHaveAnAccount: 'Sie haben bereits ein Konto? Melden Sie sich an.',
    signinWithAnotherAccount: 'Melden Sie sich mit einem anderen Konto an',
    emptyPermissions: {
      message: `Sie haben noch keine Berechtigungen. Warten Sie darauf, dass der Administrator Ihnen Berechtigungen erteilt`,
    },
    passwordResetRequest: {
      message: 'Antrag auf Passwortrücksetzung',
      error: `Email nicht erkannt`,
    },
    passwordReset: {
      message: 'Passwort zurücksetzen',
    },
    passwordChange: {
      title: 'Passwort ändern',
      success: 'Passwort erfolgreich geändert',
      mustMatch: 'Passwörter müssen übereinstimmen',
    },
    emailAddressVerificationEmail: {
      error: `Email nicht erkannt`,
    },
    verificationEmailSuccess: `Überprüfungsmail erfolgreich gesendet`,
    passwordResetEmailSuccess: `Passwortrücksetzungs-E-Mail erfolgreich gesendet`,
    passwordResetRequestSuccess: `Passwortrücksetzung erfolgreich angefordert`,
    passwordResetSuccess: `Passwort erfolgreich geändert`,
    //TODO
    //Text, der von SFU angegeben werden muss
    registrationSuccess:
      'Registrierung erfolgreich beantragt! Sie erhalten eine E-Mail mit Einzelheiten, sobald Ihre Registrierung von den Administratoren bearbeitet wurde.',
  },

  passwordChange: {
    title: 'Passwort ändern',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Kennwort',
    confirmNewPassword: 'Neues Kennwort bestätigen',
    containsEightCharacters: '8 Zeichen',
    containsNumber: 'Enthält Zahl',
    containsUppercase: 'Enthält Großbuchstaben',
    containsSpecialCharacter: 'Enthält Sonderzeichen',
    passwordUpdateSuccess: 'Passwort erfolgreich aktualisiert',
    passwordUpdateFailed: 'Kennwortaktualisierung fehlgeschlagen',
  },

  roles: {
    //TODO
    //Define description for the user roles
    itAdmin: {
      label: 'IT Administrator',
      description: 'Full access to all resources',
    },
    personalAdmin: {
      label: 'Personal Administrator',
      description: 'Full access to all resources',
    },
    personal: {
      label: 'Personal',
      description: 'Restricted access to Personal operations',
    },
  },

  user: {
    title: 'Benutzer',
    menu: 'Benutzer',
    disable: 'Deaktivieren',
    disabled: 'Deaktiviert',
    enabled: 'Aktiviert',
    enable: 'Aktivieren',
    doEnableSuccess: 'Benutzer erfolgreich aktiviert',
    doDisableSuccess: 'Benutzer erfolgreich deaktiviert',
    doDisableAllSuccess: 'Benutzer erfolgreich deaktiviert',
    doEnableAllSuccess: 'Benutzer erfolgreich aktiviert',
    doAddSuccess: 'Benutzer erfolgreich gespeichert',
    doSignupSuccess: 'Benutzerregistrierung erfolgreich angefordert',
    doUpdateSuccess: 'Benutzer erfolgreich gespeichert',
    doDestroySuccess: 'Benutzer erfolgreich gelöscht',
    doDestroyAllSuccess: 'Benutzer erfolgreich gelöscht',
    viewBy: 'Ansicht von',
    users: {
      name: 'users',
      label: 'Benutzer',
      exporterFileName: 'users_export',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Berechtigungen erfolgreich entfernt',
    },
    edit: {
      title: 'Benutzer bearbeiten',
    },
    new: {
      title: 'Neue(r) Benutzer',
      titleModal: 'Neuer Benutzer',
      emailsHint: 'Trennen Sie mehrere E-Mail-Adressen durch ein Komma.',
    },
    view: {
      title: 'Benutzer anzeigen',
      activity: 'Aktivität',
    },
    approve: {
      title: 'Benutzer genehmigen',
      approve: 'Genehmigen',
    },
    importer: {
      title: 'Benutzer importieren',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships müssen die ID der referenzierten Datensätze sein, getrennt durch Leerzeichen. Roles müssen die Rollen-IDs sein, getrennt durch ein Leerzeichen.',
    },
    fields: {
      id: 'Id',
      avatars: 'Avatar',
      email: 'E-Mail',
      emails: 'E-Mail(s)',
      anrede: 'Anrede',
      academicTitle: 'Akademischer Titel',
      fullName: 'Name',
      firstName: 'Vorname',
      lastName: 'Nachname',
      status: 'Status',
      deaktiviert: 'Deaktiviert',
      phoneNumber: 'Telefonnummer',
      role: 'Rolle',
      createdAt: 'Erstellt am',
      updatedAt: 'Aktualisiert am',
      roleUser: 'Rolle/Benutzer',
      roles: 'Rollen',
      createdAtRange: 'Erstellt am',
      password: 'Kennwort',
      oldPassword: 'Altes Kennwort',
      newPassword: 'Neues Kennwort',
      newPasswordConfirmation: 'Neues Kennwort-Bestätigung',
      rememberMe: 'An mich erinnern',
    },
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} ist ungültig',
    },
    status: {
      enabled: 'Aktiviert',
      disabled: 'Deaktiviert',
    },
    anrede: {
      frau: 'Frau',
      herr: 'Herr',
    },
    errors: {
      userAlreadyExists: 'Benutzer mit dieser E-Mail existiert bereits',
      userNotFound: 'Benutzer nicht gefunden',
      disablingHimself: `Sie können sich nicht selbst deaktivieren`,
      revokingOwnPermission: `Sie können Ihre eigene Eigentümerberechtigung nicht widerrufen`,
    },
  },

  auditLog: {
    menu: 'Audit-Protokolle',
    title: 'Audit-Protokolle',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Trennen Sie mehrere Entitäten durch ein Komma.',
    fields: {
      id: 'Id',
      timestampRange: 'Zeitraum',
      entityName: 'Entität',
      entityNames: 'Entitäten',
      entityId: 'Entitäts-ID',
      action: 'Aktion',
      values: 'Werte',
      timestamp: 'Datum',
      createdByEmail: 'Benutzer-E-Mail',
    },
  },
  settings: {
    title: 'Einstellungen',
    menu: 'Einstellungen',
    save: {
      success: 'Einstellungen erfolgreich gespeichert.',
    },
    emailTest: {
      success: 'Test E-Mail erfolgreich gesendet!.',
    },
    fields: {
      contractApprover: 'Vertragsgenehmiger',
      beendigungApprover: 'Beendigungsgenehmiger',
      externeLohnverrechnungEmail: 'Externe Lohnverrechnung Email',
      externeLohnverrechnungEmailCC: 'Externe Lohnverrechnung Email - CC',
      personalDepartmentMailbox: 'E-Mail der Personalabteilung',
      arbeitsinspektoratEmail: 'Arbeitsinspektorat Email',
      arbeitsmedizinEmail: 'Arbeitsmedizin Email',
      geringfuegigkeitsgrenze: 'Geringfügigkeitsgrenze',
      hbglProTag: 'Höchsbeitragsgrundlagen pro Tag',
      hbglProMonat: 'Höchsbeitragsgrundlagen pro Monat',
      hbglProJahr: 'Höchsbeitragsgrundlagen SZ pro Jahr',
      lieferungDerAenderungen: 'Lieferung der Änderungen',
      sv: 'SV',
      sv_60_bis_63: 'SV 60-63',
      sv_uber_63: 'SV über 63',
      sv_sz: 'SV Sonderzahlung',
      sv_sz_60_bis_63: 'SV Sonderzahlung 60-63',
      sv_sz_uber_63: 'SV Sonderzahlung über 63',
      sv_geringfuegig: 'SV Geringfügig',
      sv_geringfuegig_uber_60: 'SV Geringfügig über 60',
      db: 'DB',
      db_uber_60: 'DB über 60',
      dz: 'DZ',
      bv: 'BV',
      koSt: 'Kommunalsteuer',
      urlaubsgeldMonat: 'Urlaubsgeld Monat',
      weihnachtsgeldMonat: 'Weihnachtsgeld Monat',
      year: 'Jahr',
      email: 'Email',
      emailTestAttachment: 'Attachment',
    },
  },
  home: {
    menu: 'Home',
    message: `In case of questions please contact research.admin@sfu.ac.at.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Zurück zur Startseite',
    403: 'Tut mir leid, Sie haben keinen Zugriff auf diese Seite',
    404: 'Die von Ihnen besuchte Seite existiert leider nicht',
    500: 'Leider meldet der Server einen Fehler',
    429: 'Zu viele Anfragen. Bitte versuchen Sie es später noch einmal.',
    forbidden: {
      message: 'Verboten',
    },
    validation: {
      message: 'Es ist ein Fehler aufgetreten',
    },
    defaultErrorMessage: 'Ops, ein Fehler ist aufgetreten',
    defaultWarningMessage: 'Ops, eine Warnung ist aufgetreten',
    files: {
      noPermissions: 'Sie haben keine Berechtigung für {0} Dateien.',
    },
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} ist ungültig',
      required: '${path} ist ein Pflichtfeld',
      oneOf: '${path} muss einem der folgenden Werte entsprechen: ${values}',
      notOneOf:
        '${path} darf keinem der folgenden Werte entsprechen: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} muss ein ${type}`;
      },
      notAllFieldsFilled: 'Nicht alle Formularfelder sind ausgefüllt.',
    },
    string: {
      length: '${path} muss genau ${length} Zeichen lang sein',
      min: '${path} muss mindestens ${min} Zeichen lang sein',
      max: '${path} darf höchstens ${max} Zeichen lang sein',
      matches: '${path} muss wie folgt aussehen: "${regex}"',
      email: '${path} muss eine gültige E-Mail-Adresse enthalten',
      url: '${path} muss eine gültige URL sein',
      trim: '${path} darf keine Leerzeichen am Anfang oder Ende enthalten',
      lowercase: '${path} darf nur Kleinschreibung enthalten',
      uppercase: '${path} darf nur Großschreibung enthalten',
      selected: '${path} muss ausgewählt werden',
    },
    number: {
      min: '${path} muss größer oder gleich ${min} sein',
      max: '${path} muss kleiner oder gleich ${max} sein',
      lessThan: '${path} muss kleiner sein als ${less}',
      moreThan: '${path} muss größer sein als ${more}',
      positive: '${path} muss eine positive Zahl sein',
      negative: '${path} muss eine negative Zahl sein',
      integer: '${path} muss eine ganze Zahl sein',
      invalid: '${path} muss eine Zahl sein',
    },
    date: {
      min: '${path} muss später sein als ${min}',
      max: '${path} muss früher sein als ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path}-Feld darf keine Schlüssel verwenden, die nicht im "Objekt-Shape" definiert wurden',
    },
    array: {
      min: '${path}-Feld muss mindesten ${min} Einträge haben',
      max: '${path}-Feld darf höchstens ${max} Einträge haben',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Hochladen',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Ungültiges Format. Muss sein '{0}'.`,
  },

  importer: {
    line: 'Zeile',
    status: 'Status',
    pending: 'Ausstehend',
    imported: 'Importiert',
    error: 'Fehler',
    total: '{0} importiert, {1} in Bearbeitung und {2} mit Fehler',
    importedMessage: `Verarbeitet {0} von {1}.`,
    noNavigateAwayMessage:
      'Navigieren Sie nicht von dieser Seite weg, sonst wird der Import gestoppt.',
    completed: {
      success:
        'Import abgeschlossen. Alle Zeilen wurden erfolgreich importiert.',
      someErrors:
        'Die Verarbeitung wurde abgeschlossen, aber einige Zeilen konnten nicht importiert werden.',
      allErrors:
        'Import fehlgeschlagen. Es sind keine gültigen Zeilen vorhanden.',
    },
    form: {
      downloadTemplate: 'Laden Sie die Vorlage herunter',
      hint: 'Klicken oder ziehen Sie die Datei in diesen Bereich, um fortzufahren',
    },
    list: {
      discardConfirm:
        'Sind Sie sicher? Nicht importierte Daten gehen verloren.',
    },
    errors: {
      invalidFileEmpty: 'Die Datei ist leer',
      invalidFileExcel: 'Nur Excel (.xlsx) Dateien sind erlaubt',
      invalidFileUpload:
        'Ungültige Datei. Stellen Sie sicher, dass Sie die letzte Version der Vorlage verwenden.',
      importHashRequired: 'Import-Hash ist erforderlich',
      importHashExistent: 'Die Daten wurden bereits importiert',
    },
  },

  autocomplete: {
    loading: 'Laden...',
  },

  imagesViewer: {
    noImage: 'Kein Bild',
  },
};

export default de;
